const tfa = document.getElementById('tfa');
const rux = document.getElementById('rux');
const iolce = document.getElementById('iolce');
const dataplay = document.getElementById('dataplay');

const btntfa = document.getElementById('btntfa');
const btnrux = document.getElementById('btnrux');
const btniolce = document.getElementById('btniolce');
const btndataplay = document.getElementById('btndataplay');

const closetfa = document.getElementById('closetfa');
const closerux = document.getElementById('closerux');
const closeiolce = document.getElementById('closeiolce');
const closedataplay = document.getElementById('closedataplay');


// TFA ---------------------------------------

btntfa.addEventListener('click', function handleClick() {
    tfa.style.display = 'block';

    rux.style.display = 'none';
    iolce.style.display = 'none';
    dataplay.style.display = 'none';
});
    
closetfa.addEventListener('click', function handleClick() {
    tfa.style.display = 'none';
});


// RUX ---------------------------------------

btnrux.addEventListener('click', function handleClick() {
    rux.style.display = 'block';

    tfa.style.display = 'none';
    iolce.style.display = 'none';
    dataplay.style.display = 'none';
});
    
closerux.addEventListener('click', function handleClick() {
    rux.style.display = 'none';
});


// iolce ---------------------------------------

btniolce.addEventListener('click', function handleClick() {
    iolce.style.display = 'block';

    tfa.style.display = 'none';
    rux.style.display = 'none';
    dataplay.style.display = 'none';
});
    
closeiolce.addEventListener('click', function handleClick() {
    iolce.style.display = 'none';
});


// dataplay ---------------------------------------

btndataplay.addEventListener('click', function handleClick() {
    dataplay.style.display = 'block';

    tfa.style.display = 'none';
    rux.style.display = 'none';
    iolce.style.display = 'none';
});
    
closedataplay.addEventListener('click', function handleClick() {
    dataplay.style.display = 'none';
});
